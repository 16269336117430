import { useEffect } from "react";
import "../styles/News.css";
import Landing6Navbar from "./Landing6Navbar";

export default function News() {

    return (
        <>
            <Landing6Navbar
                top={0}
            />

            <div className='news-container'>
                <div className='news-title'>
                    Bobyard in the news
                </div>
                <div className='news-subtitle'>
                    See how Bobyard is being used in the world
                </div>
                <div className='news-items'>
                    <a href='https://www.youtube.com/watch?v=GseZBAveMDg' className="news-item-link" target="_blank">
                        <div className='news-item'>
                            <div className='news-item-image-conatiner'>
                                <img
                                    src="https://i.ytimg.com/vi/GseZBAveMDg/maxresdefault.jpg"
                                    className="news-item-image"
                                >
                                </img>
                            </div>
                            <div className='news-item-content'>
                                <div className='news-item-body'>
                                    <div className='news-item-title'>
                                        The Commercial Landscaper Podcast
                                    </div>
                                    <div className='news-item-text'>
                                        Our CEO Michael was on the Commercial Landscaper Podcast with Robert Clinkenbeard talking about how AI is transforming the industry. From estimators to owners, smarter solutions are here. Instead of hours spent manually counting plants, AI-powered tools like Bobyard are freeing up teams for higher-value work.
                                    </div>
                                </div>
                                <div className='news-item-date'>
                                    March 19, 2025
                                </div>
                            </div>
                        </div>
                    </a>

                    <a href='https://www.youtube.com/watch?v=i0u8844xKiI' className="news-item-link" target="_blank">
                        <div className='news-item'>
                            <div className='news-item-image-conatiner'>
                                <img
                                    src="https://bobyard-public-images.s3.us-west-2.amazonaws.com/Screenshot+2025-03-19+at+7.26.05%E2%80%AFPM.png"
                                    className="news-item-image"
                                >
                                </img>
                            </div>
                            <div className='news-item-content'>
                                <div className='news-item-body'>
                                    <div className='news-item-title'>
                                        Scale-Worthy Technology
                                    </div>
                                    <div className='news-item-text'>
                                        Our CEO Michael was on the main stage at SYNKD Live! The panel discussed how technology—especially AI—can help businesses scale. Spoiler: AI software is proving to be the ultimate productivity hack, saving time and reducing tedious manual work.
                                    </div>
                                </div>
                                <div className='news-item-date'>
                                    Feb 23, 2025
                                </div>
                            </div>
                        </div>
                    </a>


                    <a href='https://blog.landscapeprofessionals.org/how-to-adopt-ai-solutions-into-your-landscaping-business-for-maximum-roi/?utm_campaign=4143956-The%20Edge%20Weekly%202025&utm_medium=email&_hsenc=p2ANqtz-8v44Ak5e3fCtiwV4BHDgOYLXw0_4n7YncD9Wo8w3K-dryXdzNTxK_T4fkFGVlT54PudIs52XxD8yby6HiAs5BDpUQEdA&_hsmi=348096764&utm_content=348096764&utm_source=hs_email' className="news-item-link" target="_blank">
                        <div className='news-item'>
                            <div className='news-item-image-conatiner'>
                                <img
                                    src="https://blog.landscapeprofessionals.org/wp-content/uploads/2025/02/ai-ROI.png"
                                    className="news-item-image"
                                >
                                </img>
                            </div>
                            <div className='news-item-content'>
                                <div className='news-item-body'>
                                    <div className='news-item-title'>
                                        NALP The Edge Blog: How to Adopt AI
                                    </div>
                                    <div className='news-item-text'>
                                        How to Adopt AI Solutions into Your Landscaping Business for Maximum ROI
                                    </div>
                                </div>
                                <div className='news-item-date'>
                                    February 14, 2025
                                </div>
                            </div>
                        </div>
                    </a>


                    <a href='https://www.linkedin.com/posts/michael-ding-933185189_ai-landscaping-takeoffs-activity-7296054764671311872-nUGE?utm_source=share&utm_medium=member_desktop&rcm=ACoAACxXivcBR3ZEw3j0JL-2qs8Mu4SlTxgGZb4' className="news-item-link" target="_blank">
                        <div className='news-item'>
                            <div className='news-item-image-conatiner'>
                                <img
                                    src="https://bobyard-public-images.s3.us-west-2.amazonaws.com/1739515008115.jpeg"
                                    className="news-item-image"
                                >
                                </img>
                            </div>
                            <div className='news-item-content'>
                                <div className='news-item-body'>
                                    <div className='news-item-title'>
                                        Breakout Session at Indiana Green Expo
                                    </div>
                                    <div className='news-item-text'>
                                        Our CEO Michael spoke at the Indiana Green Expo about AI in landscaping. One big takeaway on software: The best companies don’t trust gut feelings—they trust the numbers. They measure impact on both time savings and top-line revenue.
                                    </div>
                                </div>
                                <div className='news-item-date'>
                                    February 11, 2025
                                </div>
                            </div>
                        </div>
                    </a>


                    <a href='https://blog.landscapeprofessionals.org/ai-demystified-how-lawn-and-landscape-companies-can-use-ai-effectively/' className="news-item-link" target="_blank">
                        <div className='news-item'>
                            <div className='news-item-image-conatiner'>
                                <img
                                    src="https://blog.landscapeprofessionals.org/wp-content/uploads/2025/02/AdobeStock_1003564021-scaled.jpeg"
                                    className="news-item-image"
                                >
                                </img>
                            </div>
                            <div className='news-item-content'>
                                <div className='news-item-body'>
                                    <div className='news-item-title'>
                                        NALP The Edge Blog: AI Demystified
                                    </div>
                                    <div className='news-item-text'>
                                        How Lawn and Landscape Companies Can Use AI Effectively
                                    </div>
                                </div>
                                <div className='news-item-date'>
                                    February 7, 2025
                                </div>
                            </div>
                        </div>
                    </a>


                    <a href='https://www.linkedin.com/posts/michael-ding-933185189_progreenexpo-constructiontech-takeoffs-activity-7291257849639321600-xuJb?utm_source=share&utm_medium=member_desktop&rcm=ACoAACxXivcBR3ZEw3j0JL-2qs8Mu4SlTxgGZb4' className="news-item-link" target="_blank">
                        <div className='news-item'>
                            <div className='news-item-image-conatiner'>
                                <img
                                    src="https://bobyard-public-images.s3.us-west-2.amazonaws.com/1738371333282.jpeg"
                                    className="news-item-image"
                                >
                                </img>
                            </div>
                            <div className='news-item-content'>
                                <div className='news-item-body'>
                                    <div className='news-item-title'>
                                        Bobyard at ProGreen Expo
                                    </div>
                                    <div className='news-item-text'>
                                        Our team had an incredible time at the ProGreen EXPO. Once again, our booth drew a crowd—not just from software enthusiasts, but from contractors of all backgrounds looking for smarter ways to estimate and bid. Big thanks to everyone who stopped by—we’re excited to keep driving the industry forward!
                                    </div>
                                </div>
                                <div className='news-item-date'>
                                    February 2, 2025
                                </div>
                            </div>
                        </div>
                    </a>

                    <a href='https://www.linkedin.com/posts/michael-ding-933185189_futureoflandscaping-landscapetechnology-ai-activity-7293448285774589954-zbK5?utm_source=share&utm_medium=member_desktop&rcm=ACoAACxXivcBR3ZEw3j0JL-2qs8Mu4SlTxgGZb4' className="news-item-link" target="_blank">
                        <div className='news-item'>
                            <div className='news-item-image-conatiner'>
                                <img
                                    src="https://bobyard-public-images.s3.us-west-2.amazonaws.com/1738893575993.jpeg"
                                    className="news-item-image"
                                >
                                </img>
                            </div>
                            <div className='news-item-content'>
                                <div className='news-item-body'>
                                    <div className='news-item-title'>
                                        Leaders Forum in Costa Rica
                                    </div>
                                    <div className='news-item-text'>
                                        We were at the NALP Leaders Forum in Costa Rica. Key takeaways included the importance of future-proofing your business, the rise of private equity, and the necessity of innovation. The landscaping industry is evolving rapidly, and Bobyard is here to help contractors stay ahead with smarter solutions.
                                    </div>
                                </div>
                                <div className='news-item-date'>
                                    January 29, 2025
                                </div>
                            </div>
                        </div>
                    </a>
                    <a href='https://www.linkedin.com/posts/michael-ding-933185189_disruptors-landscaping-synkdlive-activity-7289735409486438401-C6Ez?utm_source=share&utm_medium=member_desktop&rcm=ACoAACxXivcBR3ZEw3j0JL-2qs8Mu4SlTxgGZb4' className="news-item-link" target="_blank">
                        <div className='news-item'>
                            <div className='news-item-image-conatiner'>
                                <img
                                    src="https://bobyard-public-images.s3.us-west-2.amazonaws.com/1738008355971.jpeg"
                                    className="news-item-image"
                                >
                                </img>
                            </div>
                            <div className='news-item-content'>
                                <div className='news-item-body'>
                                    <div className='news-item-title'>
                                        SYNKD Live Afterhours with Disruptors
                                    </div>
                                    <div className='news-item-text'>
                                        Bobyard sponsored the SYNKD Live afterhours event, alongside Kress Outdoor Power Equipment and Weathermatic, hosted by Justin White and his Disruptors group. Great conversations and even better company!
                                    </div>
                                </div>
                                <div className='news-item-date'>
                                    January 15, 2025
                                </div>
                            </div>
                        </div>
                    </a>


                </div>
            </div>
        </>
    )
}