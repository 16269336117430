import { useState, useEffect } from 'react';
import "../styles/Landing6.css";

import { IconAbc, IconAd, IconAdFilled, IconAngle, IconArrowDown, IconArrowRight, IconArrowUp, IconArrowUpRight, IconBrandLinkedin, IconClockHour4Filled, IconCloudLock, IconCubePlus, IconDatabase, IconDimensions, IconDots, IconGrain, IconMeterSquare, IconMinusVertical, IconReceipt, IconRobot, IconRuler, IconRuler2, IconShare, IconSortAZ, IconTableExport, IconTargetArrow, IconTemplate, IconTrophyFilled, IconUpload } from '@tabler/icons-react';
import Landing6Navbar from './Landing6Navbar';
import LazyLoad from 'react-lazyload';
import Marquee from "react-fast-marquee";

export default function Landing6() {
  const [smallIcons, setSmallIcons] = useState(window.innerWidth > 650);

  const [quoteIndex, setQuoteIndex] = useState(2);


  useEffect(() => {
    const number = document.getElementById('landing6-part1-ending-number');

    const handleScroll = () => {
      if (number.getBoundingClientRect().top < window.innerHeight && number.getBoundingClientRect().top > 0) {
        if (parseInt(number.innerHTML) >= 1350742) {
          //if it is already at the end, increment the number every 3 seconds by a random int between 1 and 100
          setInterval(() => {
            number.innerHTML = parseInt(number.innerHTML) + Math.floor(Math.random() * 10) + 1;
          }, 5000);
        } else {
          let i = 2341;
          const interval = setInterval(() => {
            number.innerHTML = i;
            i += 11423;
            if (i > 1350742) {
              number.innerHTML = 1350742;
              clearInterval(interval);
            }
          }, 3);
        }
      } else if (number.getBoundingClientRect().top > window.innerHeight || number.getBoundingClientRect().top < 0) {
        number.innerHTML = '0';
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [])


  useEffect(() => {
    const interval = setInterval(() => {
      setQuoteIndex((quoteIndex + 1) % 3);
    }, 7000);

    return () => clearInterval(interval);
  }, [quoteIndex])

  useEffect(() => {
    window.addEventListener('resize', () => {
      setSmallIcons(window.innerWidth > 650);
    })
  }, [])

  return (
    <div className="landing6-container">
      <Landing6Navbar />

      <div className="landing6-content">
        <div className="landing6-section1-container">
          <div className="landing6-section1-background">
            &nbsp;
          </div>

          <div className="landing6-section1-body">
            <div className="landing6-section1">
              <div className="landing6-section1-left">
                <div className="landing6-section1-left-title">
                  AI Landscaping Takeoffs
                </div>
                <div className="landing6-section1-left-subtitle">
                  <div>Bobyard is a modern takeoff and estimating platform supercharged with AI for landscaping pros.</div>
                </div>
                <div className="landing6-section1-left-button">
                  <a href='/demo'>
                    <button className='landing6-header-menu-button'>
                      Book demo
                    </button>
                  </a>

                  <a href='/sample'>
                    <button className='landing6-header-menu-button2'>
                      Sample run
                    </button>
                  </a>
                </div>
              </div>
              <div className="landing6-section1-right">
                <img
                  className='landing6-section1-video'
                  src="https://bobyard-public-images.s3.us-west-2.amazonaws.com/CountDemo-new-ezgif.com-speed.gif"
                  autoplay
                  preload="auto"
                  type="video/mp4"
                  loop
                  muted
                  playsInline
                />
              </div>
            </div>

            <div className="landing6-section1-logos-conatiner">
              <div className="landing6-section1-logos-title">
                Trusted by next-gen landscapers
              </div>
              <div className="landing6-section1-logos" id='landing6-section1-logos'>
                <Marquee
                  speed={30}
                  autoFill={true}

                  gradient={window.innerWidth > 900 ? true : false}
                  gradientWidth={'200px'}
                  gradientColor={'#04041d'}
                >
                  <div>
                    <img
                      src="https://bobyard-public-images.s3.us-west-2.amazonaws.com/Untitled+design+(6)+(1).png"
                      className='landing6-section1-logo'
                    />
                  </div>
                  <div>
                    <img
                      src="https://bobyard-public-images.s3.us-west-2.amazonaws.com/Untitled+design+(4)+(1).png"
                      className='landing6-section1-logo'
                    />
                  </div>
                  <div>
                    <img
                      src="https://bobyard-public-images.s3.us-west-2.amazonaws.com/fGlogo-green+(1).png"
                      className='landing6-section1-logo'
                    />
                  </div>
                  <div>
                    <img
                      src="https://bobyard-public-images.s3.us-west-2.amazonaws.com/43e09820-eced-436a-b1b8-a661f4cac82c_removalai_preview+(1).png"
                      className='landing6-section1-logo'
                    />
                  </div>
                  <div>
                    <img
                      src="https://bobyard-public-images.s3.us-west-2.amazonaws.com/Untitled+design+(7)+(1).png"
                      className='landing6-section1-logo'
                    />
                  </div>
                  <div>
                    <img
                      src="https://bobyard-public-images.s3.us-west-2.amazonaws.com/image+(20).png"
                      className='landing6-section1-logo'
                    />
                  </div>
                  <div>
                    <img
                      src="https://bobyard-public-images.s3.us-west-2.amazonaws.com/rafael-landscaping-logo-white+(1)+(2).png"
                      className='landing6-section1-logo'
                    />
                  </div>
                  <div>
                    <img
                      src="https://bobyard-public-images.s3.us-west-2.amazonaws.com/California-Turf-and-Landscape-Logo+(1).png"
                      className='landing6-section1-logo'
                    />
                  </div>
                  <div>
                    <img
                      src="https://bobyard-public-images.s3.us-west-2.amazonaws.com/image+(19).png"
                      className='landing6-section1-logo'
                    />
                  </div>
                </Marquee>
              </div>
            </div>
          </div>
        </div>

        <div
          className="landing6-section2"
          id='takeoff'
        >
          <div className='landing6-section2-uparrow-background'>
            &nbsp;
          </div>

          <div className="landing6-section2-left">
            <div className="landing6-section2-top">
              <div className="landing6-section2-top-title">
                Ridiculously fast AI takeoffs
              </div>
              <div className="landing6-section2-button">
                <a href='/demo'>
                  <button className='landing6-header-menu-button'>
                    Get started
                  </button>
                </a>
              </div>

              <div className="landing6-section2-item">
                <div className='landing6-section2-item-title'>
                  <IconGrain size={20} color='#006aff' />
                  <div>
                    Auto Count
                  </div>
                </div>
                <div className='landing6-section2-item-subtitle'>
                  Automatically count planting, irrigation, and electrical symbols in seconds.
                </div>
              </div>
              <div className="landing6-section2-item">
                <div className='landing6-section2-item-title'>
                  <IconRuler2 size={20} color='#006aff' />
                  <div>
                    Measure Lengths
                  </div>
                </div>
                <div className='landing6-section2-item-subtitle'>
                  Automatically measure lengths of irrigation systems.
                </div>
              </div>
              <div className="landing6-section2-item">
                <div className='landing6-section2-item-title'>
                  <IconRuler size={20} color='#006aff' />
                  <div>
                    Measure Areas
                  </div>
                </div>
                <div className='landing6-section2-item-subtitle'>
                  Automatically measure pavers, concrete, and other materials.
                </div>
              </div>
            </div>
          </div>

          <div className="landing6-section2-right">
            <div className="landing6-section2-image-container">
              <img
                src='https://bobyard-public-images.s3.us-west-2.amazonaws.com/Website+Assets+(3).png'
                className='landing6-section2-image'
              />
            </div>
          </div>
        </div >

        <div className="landing6-section3">
          <div className="landing6-section3-image-container-mobile">
            <img
              className='landing6-section3-image'
              src="https://bobyard-public-images.s3.us-west-2.amazonaws.com/Screenshot+2025-03-13+at+9.58.52%E2%80%AFPM.png"
            />
          </div>

          <div className="landing6-section3-title">
            Instant access to key data
          </div>

          <div className="landing6-section3-button-mobile">
            <a href='/demo'>
              <button className='landing6-header-menu-button'>
                Learn more
              </button>
            </a>
          </div>


          <div className='landing6-section3-body landing6-section3-body1'>
            <div className='landing6-section3-item-header'>
              <div className='landing6-section3-item-icon'>
                <IconRobot size={20} color='#006aff' />
              </div>
              <div className='landing6-section3-item-content-title'>
                Q&A with AI
              </div>
            </div>
            <div className='landing6-section3-item-content-subtitle'>
              Ask a private model trained on your data about anything.
            </div>

            <div className="landing6-section3-button">
              <a href='/demo'>
                <button className='landing6-header-menu-button'>
                  Learn more
                </button>
              </a>
            </div>
          </div>
          <img
            className='landing6-section3-arrow landing6-section3-arrow1'
            src="https://bobyard-public-images.s3.us-west-2.amazonaws.com/linebend.png"
          />

          <div className='landing6-section3-body landing6-section3-body2'>
            <div className='landing6-section3-item-header'>
              <div className='landing6-section3-item-icon'>
                <IconCloudLock size={20} color='#006aff' />
              </div>
              <div className='landing6-section3-item-content-title'>
                Secure
              </div>
            </div>
            <div className='landing6-section3-item-content-subtitle'>
              Store and access your documents from anywhere.
            </div>
          </div>
          <img
            className='landing6-section3-arrow landing6-section3-arrow2'
            src="https://bobyard-public-images.s3.us-west-2.amazonaws.com/linebend.png"
          />

          <div className='landing6-section3-body landing6-section3-body3'>
            <div className='landing6-section3-item-header'>
              <div className='landing6-section3-item-icon'>
                <IconSortAZ size={20} color='#006aff' />
              </div>
              <div className='landing6-section3-item-content-title'>
                Auto Naming
              </div>
            </div>
            <div className='landing6-section3-item-content-subtitle'>
              Automatically split and name your drawings.
            </div>
          </div>
          <img
            className='landing6-section3-arrow landing6-section3-arrow3'
            src="https://bobyard-public-images.s3.us-west-2.amazonaws.com/linebend.png"
          />

          <div className="landing6-section3-image-container">
            <img
              className='landing6-section3-image'
              src="https://bobyard-public-images.s3.us-west-2.amazonaws.com/Screenshot+2025-03-13+at+9.58.52%E2%80%AFPM.png"
            />
          </div>
        </div>

        <div
          className="landing6-section4"
          id='estimate'
        >
          <div className='landing6-section4-background'>
            &nbsp;
          </div>

          <div className='landing6-section4-body'>
            <img
              src='https://bobyard-public-images.s3.us-west-2.amazonaws.com/Website+Assets+(5).png'
              className='landing6-section4-image'
            />

            {/*<div className='landing6-section4-table'>
                <div className='landing6-section4-table-row'>
                  <div className='landing6-section4-table-row-cell landing6-section4-table-row-title'>
                    Name
                  </div>
                  <div className='landing6-section4-table-row-cell landing6-section4-table-row-title'>
                    Quantity
                  </div>
                  <div className='landing6-section4-table-row-cell landing6-section4-table-row-title'>
                    Material
                  </div>
                  <div className='landing6-section4-table-row-cell landing6-section4-table-row-title'>
                    Labor
                  </div>
                  <div className='landing6-section4-table-row-cell landing6-section4-table-row-end'>
                    &nbsp;
                  </div>
                </div>
                <div className='landing6-section4-table-row'>
                  <div className='landing6-section4-table-row-cell'>
                    Turf
                  </div>
                  <div className='landing6-section4-table-row-cell'>
                    1243 ft<sup>2</sup>
                  </div>
                  <div className='landing6-section4-table-row-cell'>
                    $12.75
                  </div>
                  <div className='landing6-section4-table-row-cell'>
                    10 hrs
                  </div>
                  <div className='landing6-section4-table-row-cell landing6-section4-table-row-end'>
                    &nbsp;
                  </div>

                </div>

                <div className='landing6-section4-table-row'>
                  <div className='landing6-section4-table-row-cell'>
                    Lateral
                  </div>
                  <div className='landing6-section4-table-row-cell'>
                    100 ft
                  </div>
                  <div className='landing6-section4-table-row-cell'>
                    $2.50
                  </div>
                  <div className='landing6-section4-table-row-cell'>
                    1 hr
                  </div>
                  <div className='landing6-section4-table-row-cell landing6-section4-table-row-end'>
                    &nbsp;
                  </div>
                </div>

                <div className='landing6-section4-table-row'>
                  <div className='landing6-section4-table-row-cell'>
                    Shrub
                  </div>
                  <div className='landing6-section4-table-row-cell'>
                    25 ea
                  </div>
                  <div className='landing6-section4-table-row-cell'>
                    $15.00
                  </div>
                  <div className='landing6-section4-table-row-cell'>
                    1.25 hrs
                  </div>
                  <div className='landing6-section4-table-row-cell landing6-section4-table-row-end'>
                    &nbsp;
                  </div>
                </div>
                <div className='landing6-section4-table-row'>
                  <div className='landing6-section4-table-row-cell'>
                    &nbsp;
                  </div>
                  <div className='landing6-section4-table-row-cell'>
                    &nbsp;
                  </div>
                  <div className='landing6-section4-table-row-cell'>
                    &nbsp;
                  </div>
                  <div className='landing6-section4-table-row-cell'>
                    &nbsp;
                  </div>
                  <div className='landing6-section4-table-row-cell landing6-section4-table-row-end'>
                    &nbsp;
                  </div>
                </div>
              </div>*/}

            {/*<div className='landing6-section4-item landing6-section4-item1'>
                <div className='landing6-section4-item-title'>
                  <IconDimensions size={20} color='#006aff' />
                  <div>
                    Takeoffs
                  </div>
                </div>
                <div className='landing6-section4-item-subtitle'>
                  AI or manual
                </div>
              </div>

              <div className='landing6-section4-arrow landing6-section4-arrow1'>
                <IconArrowRight size={20} color='#006aff' />
              </div>

              <div className='landing6-section4-item landing6-section4-item3'>
                <div className='landing6-section4-item-cell'>
                  <IconDatabase size={50} stroke={1} color='#006aff' />
                  <div>
                    Cost Database
                  </div>
                  <IconArrowDown size={20} color='#006aff' />
                </div>

                <div className='landing6-section4-item-cell'>
                  <IconReceipt size={50} stroke={1} color='#006aff' />
                  <div>
                    Vendor Quotes
                  </div>
                  <IconArrowDown size={20} color='#006aff' />
                </div>

                <div className='landing6-section4-item-cell'>
                  <IconCubePlus size={50} stroke={1} color='#006aff' />
                  <div>
                    Assemblies
                  </div>
                  <IconArrowDown size={20} color='#006aff' />
                </div>
              </div>

              <div className='landing6-section4-item landing6-section4-item4'>
                <div className='landing6-section4-item-title'>
                  <IconTableExport size={20} color='#006aff' />
                  <div>
                    Exports
                  </div>
                </div>
                <div className='landing6-section4-item-subtitle'>
                  Custom exports to pdf, csv, or excel.
                </div>
              </div>
              <div className='landing6-section4-arrow landing6-section4-arrow4'>
                <IconArrowRight size={20} color='#006aff' />
              </div>*/}
          </div>
          <div className='landing6-section4-right'>
            <div className="landing6-section4-title">
              Estimate with confidence
            </div>
            <div className="landing6-section4-button">
              <a href='/demo'>
                <button className='landing6-header-menu-button'>
                  <nobr>Show me</nobr>
                </button>
              </a>
            </div>
            <div className='landing6-section4-right-item'>
              <div className='landing6-section4-item-title'>
                <IconTemplate size={20} color='#006aff' />
                <div>
                  Customizable Templates
                </div>
              </div>
              <div className='landing6-section4-item-subtitle'>
                Adaptable formulas to fit your business and workflows.
              </div>
            </div>
            <div className='landing6-section4-right-item'>
              <div className='landing6-section4-item-title'>
                <IconDatabase size={20} color='#006aff' />
                <div>
                  Database & Vendor Quotes
                </div>
              </div>
              <div className='landing6-section4-item-subtitle'>
                Keep track of your historical costs, vendor quotes, assemblies, and more.
              </div>
            </div>
            <div className='landing6-section4-right-item'>
              <div className='landing6-section4-item-title'>
                <IconTableExport size={20} color='#006aff' />
                <div>
                  Flexible Exports
                </div>
              </div>
              <div className='landing6-section4-item-subtitle'>
                Create custom exports to pdf or excel.
              </div>
            </div>
          </div>

          {/*
          <div className="landing6-section2-body">
            <div className='landing6-section2-body-item'>
              <div>
                <div className='landing6-section2-body-item-icon'>
                  <IconTemplate size={smallIcons ? 30 : 20} color='#006aff' />
                </div>
              </div>
              <div className='landing6-section2-body-item-content'>
                <div className='landing6-section2-body-item-content-title'>
                  Customizable templates
                </div>
                <div className='landing6-section2-body-item-content-subtitle'>
                  Adaptable formulas to fit your business and workflows.
                </div>
              </div>
            </div>

            <div className='landing6-section2-body-item'>
              <div>
                <div className='landing6-section2-body-item-icon'>
                  <IconDatabase size={smallIcons ? 30 : 20} color='#006aff' />
                </div>
              </div>
              <div className='landing6-section2-body-item-content'>
                <div className='landing6-section2-body-item-content-title'>
                  Database & vendor quotes
                </div>
                <div className='landing6-section2-body-item-content-subtitle'>
                  Keep track of your historical costs, vendor quotes, assemblies, and more.
                </div>
              </div>
            </div>

            <div className='landing6-section2-body-item'>
              <div>
                <div className='landing6-section2-body-item-icon'>
                  <IconTableExport size={smallIcons ? 30 : 20} color='#006aff' />
                </div>
              </div>
              <div className='landing6-section2-body-item-content'>
                <div className='landing6-section2-body-item-content-title'>
                  Flexible exports
                </div>
                <div className='landing6-section2-body-item-content-subtitle'>
                  Create custom exports to pdf or excel.
                </div>
              </div>
            </div>

            <div className="landing6-section2-button">
              <a href='/demo'>
                <button className='landing4-header-menu-button'>
                  Show me how
                </button>
              </a>
            </div>
          </div>*/}
        </div>

        <div className="landing6-news-container">
          <div className="landing6-news-title">
            Bobyard in the news
          </div>
          <a href='https://www.youtube.com/watch?v=GseZBAveMDg' target='_blank'>
            <div className="landing6-news-image-container">
              <img
                src="https://i.ytimg.com/vi/GseZBAveMDg/maxresdefault.jpg"
                className='landing6-news-image'
              />
            </div>
          </a>
          <div className="landing6-news-subtitle">
            Our Founder & CEO, Michael, was invited on the The Commercial Landscaper Podcast with Robert Clinkenbeard.
          </div>
          <div className="landing6-news-buttons">
            <div>
              <a href='https://www.youtube.com/watch?v=GseZBAveMDg' target='_blank'>
                <button className='landing6-header-menu-button'>
                  <nobr>Listen <IconArrowUpRight size={20} stroke={2} /></nobr>
                </button>
              </a>
            </div>
            <div>
              <a href='/news'>
                <button className='landing6-header-menu-button2'>
                  <nobr>More news <IconArrowUpRight size={20} stroke={2} /></nobr>
                </button>
              </a>
            </div>
          </div>
        </div>

        <div className="landing6-part1-ending-container">
          <div className="landing6-part1-ending-body">
            <div className="landing6-part1-ending-header">
              Focus on what matters
            </div>
            <div className="landing6-part1-ending">
              <div className="landing6-part1-ending-left">
                <div className="landing6-part1-ending-title" id='landing6-part1-ending-number'>
                  0
                </div>
                <div className="landing6-part1-ending-subtitle">
                  AI measurements made
                </div>
              </div>
              <div className="landing6-part1-ending-right">
                <div className="landing6-part1-ending-right-title">
                  80%
                </div>
                <div className="landing6-part1-ending-right-subtitle">
                  of measurements automated by AI
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="landing6-part2">
          <div className="landing6-part2-background">
            &nbsp;
          </div>

          <div className="landing6-section5">
            <div className="landing6-section5-title">
              What our customers say
            </div>

            {window.innerWidth > 450 &&
              <div className="landing6-section5-selection-coantiner">
                <div
                  className='landing6-section5-selection'
                  onClick={() => setQuoteIndex(0)}
                >
                  <img
                    src="https://bobyard-public-images.s3.us-west-2.amazonaws.com/Untitled+design+(4)+(1).png"
                    className={'landing6-section5-logo ' + (quoteIndex === 0 ? 'landing6-section5-logo-selected' : '')}
                  />
                </div>
                <div
                  className='landing6-section5-selection'
                  onClick={() => setQuoteIndex(1)}
                >
                  <img
                    src="https://bobyard-public-images.s3.us-west-2.amazonaws.com/fGlogo-green+(1).png"
                    className={'landing6-section5-logo ' + (quoteIndex === 1 ? 'landing6-section5-logo-selected' : '')}
                  />
                </div>
                <div
                  className='landing6-section5-selection'
                  onClick={() => setQuoteIndex(2)}
                >
                  <img
                    src="https://bobyard-public-images.s3.us-west-2.amazonaws.com/Untitled+design+(7)+(1).png"
                    className={'landing6-section5-logo ' + (quoteIndex === 2 ? 'landing6-section5-logo-selected' : '')}
                  />
                </div>
              </div>
            }

            {(quoteIndex === 0 || window.innerWidth <= 450) &&
              <div className="landing6-section5-quote-container">
                <div className="landing6-section5-section-body">
                  <div className="landing6-section5-section-quote">
                    "Bobyard is a <i>game-changer</i>. Instead of spending hours manually counting symbols, Bobyard does it in <i>minutes</i>, growing one of our most important KPIs: revenue per employee."
                  </div>
                  <div className="landing6-section5-desc">
                    <div className='landing6-section5-section-image'>
                      <img
                        src="https://bobyard-public-images.s3.us-west-2.amazonaws.com/Justin-White.jpg"
                        className='landing6-section5-image'
                      />
                    </div>
                    <div className='landing6-section5-desc-name'>
                      <b>Justin White</b>, CEO
                    </div>
                    {window.innerWidth > 450
                      ? <div className='landing6-section5-desc-company'>
                        K & D Landscaping
                      </div>
                      : <div>
                        <img
                          src="https://bobyard-public-images.s3.us-west-2.amazonaws.com/Untitled+design+(4)+(1).png"
                          className='landing6-section5-logo-selected'
                        />
                      </div>
                    }
                  </div>
                </div>
                {window.innerWidth > 450 &&
                  <div className="landing6-section5-button">
                    <a href='/demo'>
                      <button className='landing6-header-menu-button'>
                        Join them <IconArrowUpRight size={20} stroke={2} />
                      </button>
                    </a>
                  </div>
                }
              </div>
            }

            {(quoteIndex === 1 || window.innerWidth <= 450) &&
              <div className="landing6-section5-quote-container">
                <div className="landing6-section5-section-body">
                  <div className="landing6-section5-section-quote1">
                    "With Bobyard, we do our estimates <i>55% faster</i> and spend that time building real relationships with clients we want to partner with."
                  </div>
                  <div className="landing6-section5-desc">
                    <div>
                      <img
                        src="https://bobyard-public-images.s3.us-west-2.amazonaws.com/garycooper.jpeg"
                        className='landing6-section5-image'
                      />
                    </div>
                    <div className='landing6-section5-desc-name'>
                      <b>Gary Cooper</b>, CEO
                    </div>
                    {window.innerWidth > 450
                      ? <div className='landing6-section5-desc-company'>
                        Frank & Grossman Landscaping
                      </div>
                      : <div>
                        <img
                          src="https://bobyard-public-images.s3.us-west-2.amazonaws.com/fGlogo-green+(1).png"
                          className='landing6-section5-logo-selected'
                        />
                      </div>
                    }
                  </div>
                </div>
                {window.innerWidth > 450 &&
                  <div className="landing6-section5-button">
                    <a href='/demo'>
                      <button className='landing6-header-menu-button'>
                        Join them <IconArrowUpRight size={20} stroke={2} />
                      </button>
                    </a>
                  </div>
                }
              </div>
            }

            {(quoteIndex === 2 || window.innerWidth <= 450) &&
              <div className="landing6-section5-quote-container">
                <div className="landing6-section5-section-body">
                  <div className="landing6-section5-section-quote2">
                    "We saved <i>2 whole days</i> of work on a $1.5M bid that had 5,700 plants and 93,000 sqft. You can't compete without Bobyard."
                  </div>
                  <div className="landing6-section5-desc">
                    <div>
                      <img
                        src="https://bobyard-public-images.s3.us-west-2.amazonaws.com/1678924455998.jpeg"
                        className='landing6-section5-image'
                      />
                    </div>
                    <div className='landing6-section5-desc-name'>
                      <b>Gino Borello</b>, VP
                    </div>
                    {window.innerWidth > 450
                      ? <div className='landing6-section5-desc-company'>
                        NewPath Landscape
                      </div>
                      : <div>
                        <img
                          src="https://bobyard-public-images.s3.us-west-2.amazonaws.com/Untitled+design+(7)+(1).png"
                          className='landing6-section5-logo-selected'
                        />
                      </div>
                    }
                  </div>
                </div>
                <div className="landing6-section5-button">
                  <a href='/demo'>
                    <button className='landing6-header-menu-button'>
                      Join them <IconArrowUpRight size={20} stroke={2} />
                    </button>
                  </a>
                </div>
              </div>
            }
          </div>

          <div className="landing6-section6-container">
            <div>
              <div className="landing6-section6-title">
                The numbers speak for themselves
              </div>
              <div>
                What could your business achieve with this kind of lift?
              </div>
            </div>

            <div className="landing6-section6-body">
              <div className='landing6-section6-body-item'>
                <div className='landing6-section6-body-item-content-title'>
                  65%
                </div>
                <div className='landing6-section6-body-item-content-subtitle'>
                  Reduction in time to place bids.
                </div>
              </div>
              <div className='landing6-section6-body-item'>
                <div className='landing6-section6-body-item-content-title landing6-section6-body-item-content-title1 '>
                  $1.1m
                </div>
                <div className='landing6-section6-body-item-content-subtitle'>
                  Increase in revenue per employee.
                </div>
              </div>
              <div className='landing6-section6-body-item'>
                <div className='landing6-section6-body-item-content-title landing6-section6-body-item-content-title2'>
                  27%
                </div>
                <div className='landing6-section6-body-item-content-subtitle'>
                  Increase in revenue and profit.
                </div>
              </div>
            </div>

            <div className="landing6-section6-button">
              <a href='/demo'>
                <button className='landing6-header-menu-button'>
                  I want these results <IconArrowUpRight size={20} stroke={2} />
                </button>
              </a>
            </div>
          </div>

          <div className='landing6-backedby-container'>
            <div className='landing6-backedby-title'>
              Backed by world-class AI investors
            </div>

            <div className='landing6-backedby-logos'>
              <a href='https://www.pear.vc/' target='_blank'>
                <div className='landing6-backedby-img-container'>
                  <img src={"https://bobyard-public-images.s3.us-west-2.amazonaws.com/Pear-Logo.svg"} className='landing6-backedby-img' />
                </div>
              </a>

              <a href='https://www.primary.vc/' target='_blank'>
                <div className='landing6-backedby-img-container'>
                  <img src={"https://bobyard-public-images.s3.us-west-2.amazonaws.com/primary+vc+logo.png"} className='landing6-backedby-img' />
                </div>
              </a>

              <a href='https://www.nvidia.com/en-us/' target='_blank'>
                <div className='landing6-backedby-img-container'>
                  <img src={"https://bobyard-public-images.s3.us-west-2.amazonaws.com/NVIDIA_Inception_Program_pillars-removebg-preview.png"} className='landing6-backedby-img' />
                </div>
              </a>
            </div>
          </div>

          <div className='landing6-footer-container'>
            <div className='landing6-footer-body'>
              <div>
                <img
                  src='https://bobyard-public-images.s3.us-west-2.amazonaws.com/landing4-logo.png'
                  className='landing2-navbar-logo-img'
                />
              </div>

              <div className='landing2-footer'>
                <div>
                  <div className='landing2-footer-section-title'>
                    Product
                  </div>

                  <div className='landing2-footer-section'>
                    <a href='#takeoff' className='landing2-navbar-item'>
                      <div>
                        Takeoff
                      </div>
                    </a>

                    <a href='#estimate' className='landing2-navbar-item'>
                      <div>
                        Estimate
                      </div>
                    </a>
                  </div>
                </div>

                <div>
                  <div className='landing2-footer-section-title'>
                    Resources
                  </div>

                  <div className='landing2-footer-section'>
                    <a href='/sample' className='landing2-navbar-item'>
                      <div>
                        Sample
                      </div>
                    </a>

                    <a href='/demo' className='landing2-navbar-item'>
                      <div>
                        Demo
                      </div>
                    </a>
                  </div>
                </div>

                <div>
                  <div className='landing2-footer-section-title'>
                    Company
                  </div>

                  <div className='landing2-footer-section'>
                    <a href='/careers' className='landing2-navbar-item'>
                      <div>
                        Careers
                      </div>
                    </a>

                    <a href='mailto:info@bobyard.com' className='landing2-navbar-item'>
                      <div>
                        info@bobyard.com
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div className='landing2-footer-get-started'>
                <div className='landing2-footer-title'>
                  Bid faster today
                </div>

                <div>
                  <a href='/demo'>
                    <button className='landing4-header-menu-button'>
                      Get started <i class="fa-solid fa-angle-right"></i>
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div className='landing2-footer-bottom'>
              <div className='landing2-footer-social'>
                <a href='https://www.linkedin.com/company/bobyard/' target='_blank'>
                  <div className='landing2-footer-social-icon'>
                    <IconBrandLinkedin size={24} stroke={2} />
                  </div>
                </a>
              </div>

              <div>
                © 2025 Bobyard, Inc.
              </div>
            </div>
          </div>

        </div>
      </div >
    </div >
  )
}