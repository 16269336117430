import { IconMenu2, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";

export default function Landing6Navbar({ top }) {
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [smallIcons, setSmallIcons] = useState(window.innerWidth > 650);

    useEffect(() => {
        window.addEventListener('resize', () => {
            setSmallIcons(window.innerWidth > 900);
        })
    }, []);

    return (
        <>
            {smallIcons
                ? <div
                    className="landing6navbar-conatiner"
                    style={{
                        top: top === 0 ? '15px' : '55px',
                    }}
                >
                    <a href='/'>
                        <div className='landing6navbar-logo'>
                            <img
                                className='landing6navbar-logo-image'
                                src='https://bobyard-public-images.s3.us-west-2.amazonaws.com/landing4-logo.png'
                            />
                        </div>
                    </a>
                    <div className="landing6navbar-options">
                        <a href='/sample' className='landing6-header-menu-item-link'>
                            <div className='landing6-header-menu-item'>
                                Sample
                            </div>
                        </a>
                        <a href='/demo' className='landing6-header-menu-item-link'>
                            <div className='landing6-header-menu-item'>
                                Demo
                            </div>
                        </a>
                        <a href='/careers' className='landing6-header-menu-item-link'>
                            <div className='landing6-header-menu-item'>
                                Careers
                            </div>
                        </a>
                        <a href='/news' className='landing6-header-menu-item-link'>
                            <div className='landing6-header-menu-item'>
                                News
                            </div>
                        </a>
                        {/*<a href='/connect' className='landing6-header-menu-item-link'>
                            <div className='landing6-header-menu-item'>
                                Connect
                            </div>
                        </a>*/}
                    </div>
                    <div className="landing6navbar-actions">
                        <a href='https://app.bobyard.com/login' className='landing6-header-menu-item-link'>
                            <div className='landing6-header-menu-login-button'>
                                Login
                            </div>
                        </a>
                        <a href='/demo' className='landing6-header-menu-item-link'>
                            <div className='landing6-header-menu-button'>
                                Try now
                            </div>
                        </a>
                    </div>
                </div >
                : <>
                    <div className="landing6navbar-conatiner"
                        style={{ marginTop: window.location.pathname === '/' ? '50px' : '0px' }}
                    >
                        <a href='/'>
                            <div>
                                <img
                                    className='landing6navbar-logo-image'
                                    src='https://bobyard-public-images.s3.us-west-2.amazonaws.com/landing4-logo.png'
                                />
                            </div>
                        </a>
                        <div
                            className='landing6navbar-menu-icon'
                            onClick={() => setShowMobileMenu(!showMobileMenu)}
                        >
                            {showMobileMenu
                                ? <IconX size={20} color='white' />
                                : <IconMenu2 size={20} color='white' />
                            }
                        </div>
                    </div>

                    {showMobileMenu &&
                        <div className='landing6navbar-mobile-menu'>
                            <a href='/sample' className='landing6-header-menu-item-link'>
                                <div className='landing6-header-menu-item'>
                                    Sample
                                </div>
                            </a>
                            <a href='/demo' className='landing6-header-menu-item-link'>
                                <div className='landing6-header-menu-item'>
                                    Demo
                                </div>
                            </a>
                            <a href='/careers' className='landing6-header-menu-item-link'>
                                <div className='landing6-header-menu-item'>
                                    Careers
                                </div>
                            </a>
                            <a href='/news' className='landing6-header-menu-item-link'>
                                <div className='landing6-header-menu-item'>
                                    News
                                </div>
                            </a>
                            <a href='/demo' className='landing6-header-menu-item-link'>
                                <div>
                                    <button className='landing6-header-menu-button'>
                                        Try now
                                    </button>
                                </div>
                            </a>
                        </div>
                    }
                </>
            }
        </>
    )
}