import { useState, useEffect } from 'react';
import "../styles/Career2.css"
import LandingNavbar from './LandingNavbar';
import Landing5Navbar from './Landing5Navbar';
import Landing6Navbar from './Landing6Navbar';

export default function Career2() {
    const [currentRole, setCurrentRole] = useState('full')

    return (
        <>
            <Landing6Navbar
                top={0}
            />

            <div className='career2-container'>
                <div className='landing4-content'>
                    <div className='career2-body'>
                        <div className='career2-body-main'>
                            <div className='career2-body-main-title'>
                                Join Bobyard!
                            </div>
                            <div className='career2-body-main-text'>
                                <p>Construction is one of the largest industries in the US, but it is also one of the least technologically innovative spaces. Every year, construction firms spend over $60B on teams who create cost estimates for bids. Conducting fast and accurate cost estimates is a massive pain point because of the tedious nature of takeoffs (measuring & counting materials needed from the drawings).</p>

                                <p>Bobyard automates the construction takeoff process with CV and NLP models to make cost estimates 10x faster while eliminating mistakes. We are a lean startup backed by some of the best VCs in the world: Primary and Pear. Almost all of the work lies ahead of us. We are excited to create next-gen software for the built world!</p>
                            </div>
                        </div>

                        <div className='career2-body-roles-selector'>
                            <div
                                className={`career2-body-roles-selector-item ${currentRole === 'full' ? 'career2-body-roles-selector-item-active' : ''}`}
                                onClick={() => setCurrentRole('full')}
                            >
                                Senior Fullstack
                            </div>
                            <div
                                className={`career2-body-roles-selector-item ${currentRole === 'ae' ? 'career2-body-roles-selector-item-active' : ''}`}
                                onClick={() => setCurrentRole('ae')}
                            >
                                Account Executive
                            </div>
                            <div
                                className={`career2-body-roles-selector-item ${currentRole === 'cs' ? 'career2-body-roles-selector-item-active' : ''}`}
                                onClick={() => setCurrentRole('cs')}
                            >
                                Customer Success
                            </div>
                        </div>

                        <div className='career2-body-roles'>
                            <div className='career2-body-roles-header'>
                                <div className='career2-body-roles-title'>
                                    {currentRole === 'full' && 'Senior Fullstack Engineer (Backend Focus)'}
                                    {currentRole === 'cv' && 'Computer Vision Engineer'}
                                    {currentRole === 'cos' && 'Chief of Staff'}
                                    {currentRole === 'gtm' && 'Founding GTM'}
                                    {currentRole === 'ae' && 'Account Executive (Mid-Market)'}
                                    {currentRole === 'cs' && 'Implementation & Customer Success Specialist'}
                                </div>

                                {currentRole === 'full' && (
                                    <a href='https://wellfound.com/l/2Bbmzb' target='_blank'>
                                        <button className='career2-body-roles-apply-button'>
                                            Apply
                                        </button>
                                    </a>
                                )}

                                {currentRole === 'cv' && (
                                    <a href='https://wellfound.com/l/2ASv84' target='_blank'>
                                        <button className='career2-body-roles-apply-button'>
                                            Apply
                                        </button>
                                    </a>
                                )}

                                {currentRole === 'cos' && (
                                    <a href='https://wellfound.com/l/2Awwuf' target='_blank'>
                                        <button className='career2-body-roles-apply-button'>
                                            Apply
                                        </button>
                                    </a>
                                )}

                                {currentRole === 'gtm' && (
                                    <a href='https://wellfound.com/l/2ADeJR' target='_blank'>
                                        <button className='career2-body-roles-apply-button'>
                                            Apply
                                        </button>
                                    </a>
                                )}

                                {currentRole === 'ae' && (
                                    <a href='https://wellfound.com/l/2ASWrt' target='_blank'>
                                        <button className='career2-body-roles-apply-button'>
                                            Apply
                                        </button>
                                    </a>
                                )}

                                {currentRole === 'cs' && (
                                    <a href='https://wellfound.com/l/2Bbmsh' target='_blank'>
                                        <button className='career2-body-roles-apply-button'>
                                            Apply
                                        </button>
                                    </a>
                                )}
                            </div>
                            <div className='career2-body-roles-text'>
                                {currentRole === 'full' && (
                                    <>
                                        <div>
                                            <div><b>Position Overview</b></div>
                                            <p>We have more than doubled revenue each month for the past few months. We are onboaring more customers than our infra can keep up, all the while, we need to keep building new products.</p>

                                            <p>You will have very high autonomy in designing, executing, and iterating on products. We are a startup, and we move fast. You will be the world’s leading expert on the products you build. We look for world-class engineers who have both a vision for the product but also the ability to go heads down and produce exceptional code.</p>

                                            <p>You will be working very closely with the founding team to develop & maintain the entire tech stack (React, Django, PostgreSQL, AWS, GCP, and Heroku).</p>
                                        </div>

                                        <br></br>

                                        <div>
                                            <div><b>Desired Attributes</b></div>
                                            <div>
                                                <ul>
                                                    <li>Learning Rate: Ability to adapt and learn new technologies and frameworks swiftly</li>
                                                    <li>Passion: Startups are not easy, this is not a cushy SWE job at Google</li>
                                                    <li>Incredibly proud of the code you write and advocate for high standards</li>
                                                    <li>Obsession with the customer and their experience</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div>
                                            <div><b>Benefits</b></div>
                                            <div>
                                                <ul>
                                                    <li>Competitive salary plus equity options (target $150k – $180k base + equity)</li>
                                                    <li>Opportunity to collaborate with a dynamic, driven team</li>
                                                    <li>Build a massive company</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </>
                                )}

                                {currentRole === 'cv' && (
                                    <>
                                        <div>
                                            <div><b>Position Overview</b></div>
                                            <p>Bobyard solves non-trivial CV problems in order to automate takeoffs for contractors. We can save them dozens of hours for each project easily. This requires a complex system of models that produce better than human results.</p>

                                            <p>You will have very high autonomy in designing, executing, and iterating on products. We are a startup, and we move fast. You will be the world’s leading expert on the products you build. We look for world-class engineers who have both a vision for the product but also the ability to go heads down and produce exceptional code.</p>

                                            <p>You will be responsible for developing state-of-the-art CV algorithms and models for segmentation, object detection, OCR, one-shot learning, and PDF vector reading techniques. You will take the products you work on from 0 to 1.</p>
                                        </div>

                                        <br></br>

                                        <div>
                                            <div><b>Desired Attributes</b></div>
                                            <div>
                                                <ul>
                                                    <li>Learning Rate: Ability to adapt and learn new technologies and frameworks swiftly</li>
                                                    <li>Passion: Startups are not easy, this is not a cushy SWE job at Google</li>
                                                    <li>Incredibly proud of the code you write and advocate for high standards</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div>
                                            <div><b>Benefits</b></div>
                                            <div>
                                                <ul>
                                                    <li>Competitive salary plus equity options (target $150k - $180k base + equity)</li>
                                                    <li>Opportunity to collaborate with a dynamic, driven team</li>
                                                    <li>Build a massive company</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <br></br>

                                        <div>This is a full-time & in-person role in the SF Bay Area. Learning rate and dedication are vital factors. If you can prove that you can execute on the products our customers are waiting for at the speed and quality the market demands (or if you can prove that you will acquire the ability to do so fast enough), we would love to work with you.</div>
                                    </>
                                )}

                                {currentRole === 'cos' && (
                                    <>
                                        <div>
                                            <div><b>Position Overview</b></div>
                                            <p>Your responsibility is simple: solve any problem that gets thrown your way. You'll work directly with the founder & CEO to do whatever it takes to win:</p>

                                            <ul>
                                                <li>Figure out conventions, pricing, marketing campaigns, and partnerships</li>
                                                <li>Find the best talent and recruit them</li>
                                                <li>Build decks for board meetings and funding rounds</li>
                                                <li>HR, finance, operations, and whatever else is needed</li>
                                            </ul>
                                        </div>

                                        <br></br>

                                        <div>
                                            <div><b>Desired Attributes</b></div>
                                            <div>
                                                <ul>
                                                    <li>Highly motivated and willing to roll up your sleeves</li>
                                                    <li>Learning speed & resourceful: Ability to adapt, learn, and hunt down answers</li>
                                                    <li>Passion and work ethic: Startups are not easy, this is not a cushy job at Google</li>
                                                    <li>Organized: you will be thrown thousands of things</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div>
                                            <div><b>Benefits</b></div>
                                            <div>
                                                <ul>
                                                    <li>Competitive salary plus equity options (target $80k - $120k)</li>
                                                    <li>Opportunity to collaborate with a dynamic, driven team</li>
                                                    <li>Build a massive company</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </>
                                )}

                                {currentRole === 'gtm' && (
                                    <>
                                        <div>
                                            <div><b>Position Overview</b></div>
                                            <div>Partner with the founder/CEO to create and execute GTM strategies for Bobyard:</div>

                                            <ul>
                                                <li>Defining ICP</li>
                                                <li>Build pipeline</li>
                                                <li>Distribution channels & partners</li>
                                                <li>Marketing & branding</li>
                                            </ul>
                                        </div>

                                        <div>
                                            <div><b>Desired Attributes</b></div>
                                            <div>
                                                <ul>
                                                    <li>Deep GTM expertise: preferably selling SaaS to the construction (landscaping) industry</li>
                                                    <li>Passion and work ethic: startups are not easy, this is not a cushy job at Google</li>
                                                    <li>Willing to do whatever it takes to win: travel, convention booths, cold calls</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div>
                                            <div><b>Benefits</b></div>
                                            <div>
                                                <ul>
                                                    <li>Competitive salary, commission, and equity options</li>
                                                    <li>Opportunity to collaborate with a dynamic, driven team</li>
                                                    <li>Build a massive company</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </>
                                )}

                                {currentRole === 'ae' && (
                                    <>
                                        <div>
                                            <div><b>Position Overview</b></div>
                                            <p>We have more than doubled revenue each month for the past few months. Your job is to make sure we keep doubling. We have very ambitious goals for 2025, and all the pieces are lined up to for you.</p>

                                            <p>Your focus is mid-market accounts. You will be expected to take ownership of the entire funnel. Everything from building pipelines, product demos, and closing.</p>
                                        </div>

                                        <br></br>

                                        <div>
                                            <div><b>Desired Attributes</b></div>
                                            <div>
                                                <ul>
                                                    <li>Deep GTM expertise: preferably selling SaaS to the construction (landscaping) industry</li>
                                                    <li>Passion and work ethic: startups are not easy, this is not a cushy job at Google</li>
                                                    <li>Willing to do whatever it takes to win: travel, convention booths, cold calls</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div>
                                            <div><b>Benefits</b></div>
                                            <div>
                                                <ul>
                                                    <li>Competitive salary, commission, and equity options ($60k - $80k base + $100k OTE commission)</li>
                                                    <li>Opportunity to collaborate with a dynamic, driven team</li>
                                                    <li>Build a massive company</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <br></br>

                                        <div>This is a full-time & in-person role in SF. We have a product that blows people’s minds and a massive industry eager to incorporate AI. If you can create unbelievable growth numbers, we would love to work with you.</div>
                                    </>
                                )}

                                {currentRole === 'cs' && (
                                    <>
                                        <div>
                                            <div><b>Position Overview</b></div>
                                            <p>We have more than doubled revenue each month for the past few months. This means that we are closing more customers than we can fit onboarding sessions for on our calendars.</p>

                                            <p>Your job is simple: make sure the customers love our product.</p>

                                            <div>
                                                <ul>
                                                    <li>Help them onboard & implement</li>
                                                    <li>Proactively help customers get more value</li>
                                                    <li>Relay feedback to the engineering team</li>
                                                    <li>Help with product demos/customer case studies</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <br></br>

                                        <div>
                                            <div><b>Desired Attributes</b></div>
                                            <div>
                                                <ul>
                                                    <li>Tech-savvy and great communication: we build the most technical product in the industry, you need to be able to teach our customers how to use it</li>
                                                    <li>Passion and work ethic: startups are not easy, this is not a cushy job at Google</li>
                                                    <li>Willing to do whatever it takes to win.</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div>
                                            <div><b>Benefits</b></div>
                                            <div>
                                                <ul>
                                                    <li>Competitive salary (target $70k - $90k base, 75% of total comp), commission (25% of total comp), and equity options</li>
                                                    <li>Opportunity to collaborate with a dynamic, driven team</li>
                                                    <li>Build a massive company</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <br></br>

                                        <div>This is a full-time & in-person role in SF. We have a product that blows people’s minds and a monster sales team in a massive industry eager to incorporate AI. You will be the primary person doing CS in the company, we will all depend on you.</div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}