import { useState } from "react";
import "../styles/Demo2.css"
import LandingNavbar from "./LandingNavbar";
import Landing5Navbar from "./Landing5Navbar";
import Landing6Navbar from "./Landing6Navbar";
import { InlineWidget } from "react-calendly";

export default function Demo2() {
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    return (
        <>
            <Landing6Navbar
                top={0}
            />

            <div className='demo2-container'>
                <div className='demo2-content'>
                    {/*<iframe
                        //src="https://calendly.com/d/cmm7-h2j-6jm/bobyard-demo"
                        //src="https://calendly.com/scott-bobyard/call-with-bobyard"
                        src="https://calendly.com/scott-bobyard/call-with-bobyard?background_color=04041d&text_color=dcecff&primary_color=006aff&embed_type=Inline"
                        className="demo2-iframe"
                    ></iframe>*/}
                    <InlineWidget
                        url="https://calendly.com/scott-bobyard/call-with-bobyard?background_color=04041d&text_color=dcecff&primary_color=006aff" />
                </div>
            </div >
        </>
    )
}