import { useState } from "react";
import "../styles/DemoRequest.css"
import Landing5Navbar from "./Landing5Navbar";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import { validateEmail } from "../helper/validateEmail";
import { useDropzone } from "react-dropzone";
import { IconUpload } from "@tabler/icons-react";
import { Blocks } from "react-loader-spinner";
import emailjs from '@emailjs/browser';
import Landing6Navbar from "./Landing6Navbar";

export default function DemoRequest() {
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [file, setFile] = useState(null);

    const [sending, setSending] = useState(false);
    const [sucess, setSucess] = useState(false);

    const handleSubmit = (e) => {
        setSending(true);

        let uuid = uuidv4();

        axios({
            method: 'post',
            url: 'https://bobyard-pilot-backend-eee9a8701ac3.herokuapp.com/api/demo-requests/',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: {
                uuid: uuid,
                name: name,
                company: company,
                email: email,
                number: number,
                file: file,
            },
        }).then((response) => {
            console.log(response);

            setSending(false);
            setSucess(true);
            setName('');
            setCompany('');
            setEmail('');
            setNumber('');
            setFile(null);

            emailjs.send('service_b7o28e7', 'template_5i1t3hp', {
                subject: `Bobyard AI sample run request`,
                message: `Hey ${name},

                    Thank you for your interest in Bobyard AI. Our team will get back to you shortly with a sample run of your drawing.

                    Best,
                    Bobyard Team

                    ---------------------------

                    Request details:
                    - Name: ${name}
                    - Company: ${company}
                    - Email: ${email}
                    - Number: ${number}
                    - File: ${uuid}
                `,
                to_email: email,
                from_name: "Bobyard",
                reply_to: "michael@bobyard.com",
                cc: "mding26@stanford.edu",
            }, '-m4vEBkRqbfzUUFlC')
                .then((result) => {
                    console.log(result);
                }, (error) => {
                    console.log(error);
                });
        }).catch((error) => {
            console.log(error);
        })
    }

    const onDrop = (acceptedFiles) => {
        setFile(acceptedFiles[0]);
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'image/png': ['.png', '.jpg', '.jpeg'],
            'application/pdf': ['.pdf']
        }
    })

    return (
        <>
            <Landing6Navbar
                top={0}
            />

            <div className='demorequest-outer-container'>
                <div className='demorequest-container'>
                    <div className='demorequest-header'>
                        <div className='contact-body-title'>
                            Sample run
                        </div>
                        <div className='contact-body-desc'>
                            Upload a drawing here and our team will get back to you with a walkthrough of Bobyard AI models doing the work for you.
                        </div>
                    </div>

                    <div className='demorequest-body'>
                        <div className="demorequest-message-input-container">
                            <div className='demorequest-body-inputs'>
                                <input
                                    className="demorequest-message-input"
                                    type="text"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <input
                                    className="demorequest-message-input"
                                    type="text"
                                    placeholder="Company"
                                    value={company}
                                    onChange={(e) => setCompany(e.target.value)}
                                />
                            </div>
                            <div className='demorequest-body-inputs'>
                                <input
                                    className="demorequest-message-input"
                                    type="text"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <input
                                    className="demorequest-message-input"
                                    type="text"
                                    placeholder="Number"
                                    value={number}
                                    onChange={(e) => setNumber(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='demorequest-body-section'>
                            <div>
                                {file ?
                                    <div className='demorequest-body-file'>
                                        <div>
                                            <b>File chosen:</b> {file.name?.length > 20 ? file.name.slice(0, 20) + '...' + file.name.slice(-4) : file.name}
                                        </div>
                                    </div>
                                    : <div className='demorequest-body-file'>
                                        <div>
                                            No file chosen
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className='demorequest-body-input'>
                                <input
                                    id='upload-file'
                                    type='file'
                                    multiple
                                    accept='.pdf,.png,.jpg,.jpeg'
                                    onChange={(e) => setFile(e.target.files[0])}
                                    style={{ display: 'none' }}
                                />

                                {/*<button
                                id='projectdetails-upload-icon'
                                className='projectdetails-upload-button'
                                onClick={() => document.getElementById('upload-file').click()}
                            >
                                <IconUpload
                                    size={28}
                                />
                            </button>*/}

                                <div
                                    className='demorequest-upload-placeholder'
                                    onClick={() => document.getElementById('upload-file').click()}
                                    {...getRootProps()}
                                    style={{
                                        cursor: 'pointer',
                                        border: isDragActive && '2px dashed #006AFF',
                                        backgroundColor: isDragActive && 'aliceblue'
                                    }}
                                >
                                    <input
                                        {...getInputProps()}
                                    />

                                    <div className='demorequest-upload-placeholder-title'>
                                        <div>
                                            <IconUpload size={60} />
                                        </div>
                                    </div>
                                    <div
                                        className='demorequest-upload-placeholder-body'
                                        style={{
                                            color: isDragActive && '#006AFF !important'
                                        }}
                                    >
                                        {isDragActive
                                            ? <>Drop the files here</>
                                            : <>Drag & drop, or click to select</>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='demorequest-request-button-container'>
                            {sending
                                ? <div className='demorequest-request-sending'>
                                    <Blocks
                                        visible={true}
                                        height="60"
                                        width="60"
                                        color="#006AFE"
                                        ariaLabel="blocks-loading"
                                        radius="10"
                                        wrapperStyle={{}}
                                        wrapperClass="blocks-wrapper"
                                    />

                                    <div className='demorequest-request-sending-text'>
                                        Uploading file...
                                    </div>
                                </div>
                                : sucess
                                    ? <div className='demorequest-request-sucess'>
                                        Request sent successfully!
                                    </div>
                                    : name && email && file && number && validateEmail(email)
                                        ? <div
                                            className='demorequest-request-button'
                                            onClick={handleSubmit}>
                                            Request sample
                                        </div>
                                        : <div
                                            className='demorequest-request-button demorequest-request-button-disabled'
                                        >
                                            Request sample
                                        </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}